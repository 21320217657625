<template>
	<div class="box">
		<div class="title" v-if="invalid">
			此页面无效
		</div>
		<div v-else>
			<div class="banners">

				<img :src='baseUrl+item.publicImage' :style="{ display: !item ? 'none' : 'block' }" />
				<!-- <img :src='item.public_image'/> -->
				<div class="logos">{{ item.name }}</div>
				<div class="btn" @click="equipment">Learn about 3D model>></div>
			</div>
			<div class="slg">
				<div class="boardTitle">
				</div>
				<div class="next">
					<img src="../assets/img/h5next.png" alt="">
				</div>
			</div>
			
			<div class="videos">
				<video class="videoBody-video" download="dsd" :src="baseUrl+item.publicVideo" controls="controls">
					您的浏览器不支持视频播放
				</video>
			</div>

			<div class="boardWrod">
				1. Breakthrough patented high-end machine tool virtual simulation algorithm, 3D virtual world machine tool movement without delay, intelligent virtual reality VR simulation technology.<br>
				2. High simulation restores 3D space and mechanical equipment, and visits various areas of VR showroom and production plants through immersive VR equipment.<br>
				3. Virtual tour guide, follow-up explanation and display equipment at any time.<br>
				4. Multi-terminal support, VR glasses, PC, you can visit anytime and anywhere.<br>
				<div style="text-align: center;">Hotline:<a :href="'tel:'+ item?.company?.telPhone">{{ item?.company?.telPhone }}</a></div>
			</div>
			<div class="bg">
				<div class="none"></div>
				<div class="sign"></div>
				<div class="form">
					<p>
						<b class="label">Email:</b>
						<span>
							<img src="../assets/img/h5email.png" alt="" class="emailImg">
							<input type="email" placeholder="Enter your email" v-model="form.email">
						</span>
					</p>
					
					<p>
						<b class="label">Verification:</b>
						<span class="code">
							<img src="../assets/img/h5captcha.png" alt="" class="codeImg">
							<input type="text" placeholder="Enter the verification from email" v-model="form.code">
						</span>
						<span class="codeBtn" @click="codeBtn" v-if="timerTf === false">Get</span>
						<span class="codeBtns" disabled v-else>{{ codeNum }}秒后重新获取</span>
					</p>
					
					<p>
						<b class="label">Name:&emsp;&emsp;</b>
						<span>
							<img src="../assets/img/h5user.png" alt="" class="userImg">
							<input type="text" v-model="form.name" placeholder="What would you like to be called">
						</span>
					</p>

					<p>
						<b class="label">Mobile:</b>
						<span class="tel">
							<img src="../assets/img/h5iPhone.png" alt="" class="telImg">
							<input type="text" placeholder="Enter mobile number as sign in account" v-model="form.mobile">
						</span>
					</p>
					
					<p>
						<b class="label">Password:</b>
						<span>
							<img src="../assets/img/h5password.png" alt="" class="passImg">
							<input type="password" class="passInp" placeholder="Enter and set password" v-model="form.password"
								id="pwd" @input="passwordBtn">
							<img src="../assets/img/yanjing_yincang_o.png" alt="" class="passIcon" @click="incoBtn"
								:style="{ display: state ? 'block' : 'none' }" v-if="this.flag === 0">
							<img src="../assets/img/yanjing-1.png" alt="" class="passIcon" @click="incoBtn"
								:style="{ display: state ? 'block' : 'none' }" v-else>
						</span>
					</p>
					<div class="signBtn" @click="submit">Submit</div>
				</div>
			</div>
			<div class="dov" style="display: block;">
				<div class="sign down" style="text-align: center;padding: 5px;color: #337ecc;font-size: 26px;font-weight: bold;"></div>
				<div class="dovForm">
					<p><span style="width: 450rem;text-align: left;">Baidu Netdisk: </span><input type="text" v-model="http" id='http' /></p>
					<!-- <p>提取码:&emsp;&emsp;&emsp;<span></span></p> -->
					<div class="num">
						<p><span style="width: 450rem;text-align: left;">Code:</span><input type="text" v-model="extractCode" /></p>
						<b>注：该软件只支持在Windows系统运行</b>
					</div>
					<div class="btn" @click="copy"><img src="../assets/img/h5icon.png" />Copy</div>
				</div>
			</div>
			
			<div class="dov" v-if="item.netdiskDownloadUrl">
				<div class="sign2" style="text-align: center;padding: 5px;color: #337ecc;font-size: 26px;font-weight: bold;"></div>
				<div class="dovForm">
					<p><span style="width: 450rem;text-align: left;">{{item?.netdiskType}}: </span><input type="text" v-model="item.netdiskDownloadUrl" id='http2' /></p>
					<!-- <p>提取码:&emsp;&emsp;&emsp;<span></span></p> -->
					<div class="num">
						<p><span style="width: 450rem;text-align: left;">Code:</span><input type="text" v-model="item.netdiskDownloadPassword" id="pwd2" /></p>
						<b>注：该软件只支持在Windows系统运行</b>
					</div>
					<div class="btn" @click="copy2"><img src="../assets/img/h5icon.png" />Copy</div>
				</div>
			</div>
			
			
		</div>

	</div>
</template>

<script>
	//import company from '@/api/company'
	import user from '@/api/user'
	import expo from '@/api/expo'
	import '@/utils/rem'


	export default {
		name: 'AHome',
		data() {
			return {
				ids: '',
				form: {
					name: '',
					mobile: '',
					code: '',
					password: '',
					email: '',
					exhibition_id: this.$route.query.id
				},
				timerTf: false,
				codeNum: 60,
				codeTime: null,
				flag: 0,
				state: false,
				http: 'https://pan.baidu.com/s/1u171_pm5MznBhV7ysXGLqw',
				extractCode: 'nf2i',
				msg: '',
				item: '',
				videos: '',
				url: '',
				invalid: false,
				baseUrl:process.env.VUE_APP_BASE_IMG
			};

		},

		mounted() {
			//截取地址栏id参数
		},
		created() {
			//this.baseUrl = process.env.VUE_APP_BASE_IMG;
			//console.log(this.form.exhibition_id)
			document.title = 'Virtual Expo Official Site-E-Dynamic'
			this.detailList()
		},
		methods: {
			//提前了解设备
			equipment() {
				//console.log(this.item)
				window.open(this.baseUrl+this.item?.modelFile?.modelPath, '_blank')
			},
			//验证码
			codeBtn() {
				if (this.form.email === '') {
					return this.$message.error('请输入您的邮箱');
				} else {
					user.VerificCode(this.form.email,2,this.$route.query.id).then(() => {
						this.codeTime = setInterval(() => {
							this.timerTf = true
							this.codeNum--
							if (this.codeNum < 0) {
								this.timerTf = false
								clearInterval(this.codeTime)
								this.codeNum = 60
							}
						}, 1000)
					}).catch(err => {
						//console.log(err)
						this.$message.error(err);
					})
				}
			},
			//注册
			submit() {
				expo.Register(this.form).then(() => {
					//console.log(res);
					this.$message.success('注册成功，请下载软件去登录体验吧');
					//alert('注册成功请下载软件去登录体验吧');
				}).catch(err=>{
					this.$message.error(err);
				})
			},
			//会展详情
			detailList() {
				let id = this.$route.query.id;
				expo.Detail(id).then((data)=>{
					this.item = data.toObject();
					//console.log(this.item)
					this.invalid = false
				}).catch(err=>{
					this.$message.error(err);
					//alert('此展会暂未开放')
					//this.$message.error('此展会暂未开放');
					//this.$router.back()
					//console.log(err)
					this.invalid = true
				});
			},
			//复制
			copy() {
				document.getElementById("http").select(); // 选择对象
				document.execCommand("Copy"); // 执行浏览器复制命令
				//  alert('复制成功')
			},
			copy2() {
				document.getElementById("http2").select(); // 选择对象
				//document.getElementById("pwd2").select(); // 选择对象
				
				document.execCommand("Copy",false); // 执行浏览器复制命令
				//  alert('复制成功')
			},
			//
			passwordBtn() {
				if (this.form.password === '') {
					this.state = false
				} else {
					this.state = true
				}
			},
			incoBtn() {
				var pwd = document.getElementById('pwd');
				if (this.flag == 0) {
					pwd.type = 'text';
					this.flag = 1; //赋值操作
				} else {
					pwd.type = 'password';
					this.flag = 0;

				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	.box {
		width: 100%;
		height: 100%;
		overflow: hidden;

		.title {
			width: 100vw;
			height: 100vh;
			text-align: center;
			line-height: 100vh;
			background-image: linear-gradient(#2a8bf6, #69aefc, #067cfd);
			background-clip: text;
			-webkit-background-clip: text;
			color: transparent;
			text-shadow: 5px 5px 5px rgba($color: #2a8bf6, $alpha: .3);
			font-size: 300rem;
		}

		.banners {
			width: 100%;
			height: 100%;
			background-color: #eee;
			position: relative;
			/*left: -100%;*/
			border-radius: 0 0 81% 81%;
			overflow: hidden;
			background-image: url(../assets/img/video_bg.png);
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}

			.logos {
				width: 800rem;
				text-align: center;
				position: absolute;
				top: 80rem;
				left: 11%;
				text-align: left;
				font-size: 60rem;
				// font-family: 'Tahoma';
				font-family: '锐字逼格青春体简2.0-闪 常规';
				font-weight: 900;
				background-image: linear-gradient(#2a8bf6, #69aefc, #067cfd);
				background-clip: text;
				-webkit-background-clip: text;
				color: transparent;
				text-shadow: 5px 5px 5px rgba($color: #2a8bf6, $alpha: .3);

			}

			.btn {
				width: 600rem;
				height: 188rem;
				position: absolute;
				left: 25%;
				top: 300rem;
				transform: translate(-50%);
				background: url(../assets/img/h5banner_button.png) no-repeat;
				background-size: 100% 100%;
				font-size: 40.8rem;
				line-height: 188rem;
				text-align: center;
				color: #fff;
				cursor: pointer;
			}
		}
		.slg {
			margin-top: -400rem;
			padding-top: 400rem;
			background-image: url(../assets/img/video_bg.png);
		}
		.boardTitle {
			width: 60%;
			height: 153.6rem;
			margin: 0 auto;
			margin-top: 30px;
			background: url(../assets/img/-s-title1.png) no-repeat;
			background-size: 100% 100%;
			
		}

		.next {
			width: 34px;
			height: 34px;
			margin: 0 auto;
			margin-top: 20px;
		}

		.videos {
			width: 100%;
			height: 1280rem;
			padding: 0 18px;
			margin: -100rem 0 102.5rem 0;
			background-image: url(../assets/img/video_bg.png);
			video {
				width: 100%;
				height: 100%;
			}
		}

		.boardWrod {
			width: 96%;
			margin: 20px auto;
			font-size: 46.32rem;
			text-align: left;
			color: #484f5a;
			line-height: 100rem;

			span {
				color: #0079ff;
			}
		}

		.bg {
			width: 100%;
			height: 2570rem;
			background: url(../assets/img/h5bg.png) no-repeat;
			background-size: 100% 100%;

			.none {
				height: 60px;
			}

			.sign {
				width: 80%;
				height: 123rem;
				margin: 0 auto;
				background: url(../assets/img/-s-title2.png) no-repeat;
				background-size: 100% 100%;
			}

			.form {
				width: 94%;
				height: 1980rem;
				margin: 0 auto;
				background: url(../assets/img/h5kuang.png) no-repeat;
				background-size: 100% 100%;
				font-size: 24rem;
				padding: 0 77rem;
				margin-top: 35rem;

				p:nth-child(1) {
					padding: 336rem 0 112rem 0;
				}

				p {
					display: flex;
					align-items: center;
					height: 180rem;
					width: 96%;
					margin: 52rem 0;

					.telIcon {
						margin: 12px 5px 0 5px;
						width: 0px;
						height: 0px;
						border: 8px solid #f5f5f5;
						border-top-color: #7e7e7e;
					}

					b {
						font-weight: normal;
						flex-shrink: 0;
						color: #1f2224;
						font-size: 35rem;
						font-weight: 900;
						width: 350rem;
					}

					span {

						flex: 1;
						height: 120rem;
						background-color: #f5f5f5;
						display: flex;
						align-items: center;
						margin-left: 24rem;
						border-radius: 50rem;

						img {
							margin: 0 10px 0 100rem;
						}

						.passInp {
							width: 870rem;
						}

						.passIcon {
							width: 75rem;
							height: 70rem;
							cursor: pointer;
						}

						.userImg {
							width: 59rem;
							height: 59rem;
						}

						.telImg {
							width: 45rem;
							height: 80rem;
						}

						.codeImg {
							width: 57rem;
							height: 63rem;
						}

						.passImg {
							width: 52rem;
							height: 64rem;
						}

						.emailImg {
							width: 70rem;
							height: 52rem;
						}

						input {
							width: 990rem;
							border: none;
							outline: none;
							background-color: #f5f5f5;
						}
					}

					.tel {
						width: 77%;

						img {
							flex-shrink: 0;
						}

						input {
							width: 122px;

							input::-webkit-input-placeholder {
								color: #ccc;
								font-size: 46rem;
							}
						}
					}

					.code {
						width: 50%;

						input {
							width: 100%;
						}
					}

					.codeBtn {
						flex: 0.3;
						height: 120rem;
						background: url(../assets/img/h5copy_tutton.png) no-repeat;
						background-size: 100% 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 41rem;
						color: #fff;
						cursor: pointer;
					}

					.codeBtns {
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: no-drop;
					}
				}

				.signBtn {
					width: 724rem;
					height: 205rem;
					background: url('../assets/img/h5submit_button.png') no-repeat;
					background-size: 100% 100%;
					line-height: 205rem;
					text-align: center;
					margin: 0 auto;
					color: #fff;
					font-size: 62rem;
					margin-top: 180rem;
					cursor: pointer;
				}
			}
		}

		.dov {
			width: 100%;
			height: 1800rem;
			background-color: #fff;

			.sign {
				width: 1625.6rem;
				height: 169rem;
				margin: 0 auto;
				margin-top: 60px;
				margin-bottom: 70px;
				background: url(../assets/img/-s-title3.png) no-repeat;
				background-size: 100% 100%;
			}
			
			.sign2 {
				width: 1625.6rem;
				height: 169rem;
				margin: 0 auto;
				margin-top: -200rem;
				margin-bottom: 70px;
				background: url(../assets/img/-s-title4.png) no-repeat;
				background-size: 100% 100%;
			}

			.dovForm {
				width: 100%;
				font-size: 61rem;
				text-align: center;
				padding: 0 100rem;
				color: #888f95;

				p {
					height: 180rem;
					display: flex;
					align-items: center;
					margin: 50rem 0;

					input {
						display: inline-block;
						width: 75%;
						height: 180rem;
						margin-left: 25.6rem;
						background-color: #f5f5f5;
						border-radius: 90rem;
						border: 0;
						outline: none;
						padding-left: 100rem;
						font-size: 46rem;
						color: #35393D;
					}
				}

				b {
					font-weight: normal;
					color: #81888e;
				}

				.btn {
					width: 768rem;
					height: 205rem;
					margin: 205rem auto;
					display: flex;
					align-items: center;
					justify-content: center;
					background: url(../assets/img/h5copy_tutton.png) no-repeat;
					background-size: 100% 100%;
					font-size: 72rem;
					color: #fff;
					cursor: pointer;

					img {
						width: 87rem;
						height: 92rem;
						margin-right: 10rem;
					}
				}
			}
		}

	}

	@media screen and (min-width: 1025px) {
		.box {
			.title {
				font-size: 80rem;
			}

			.banners {
				width: 100%;
				height: 800rem;
				background-color: #eee;
				position: relative;
				left: -0%;
				border-radius: 0 0 80% 80%;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
				}

				.logos {
					width: 800rem;
					position: absolute;
					top: 222rem;
					left: 570rem;
					font-size: 50rem;
					// font-family: 'Tahoma';
					font-weight: 900;
					background-image: linear-gradient(#2a8bf6, #69aefc, #067cfd);
					background-clip: text;
					-webkit-background-clip: text;
					color: transparent;
					text-shadow: 5px 5px 5px rgba($color: #2a8bf6, $alpha: .3);
					text-align: left;
				}

				.btn {
					width: 430rem;
					height: 100rem;
					position: absolute;
					left: 766rem;
					top: 360rem;
					// transform: translate(-50%);
					background: url(../assets/img/h5banner_button.png) no-repeat;
					background-size: 100% 100%;
					font-size: 30rem;
					line-height: 100rem;
					text-align: center;
					color: #fff;
					cursor: pointer;
				}
			}

			.slg {
				margin-top:-376.80rem;
				background-image: url(../assets/img/web/video_bg.png);
				background-size: 100%;
				margin-bottom: 100rem;
				.boardTitle {
					width: 100%;
					height: 38rem;
					margin: 0 auto;
					margin-top: 30px;
					background: url(../assets/img/web/title1.png) no-repeat;
					background-position: center;
					z-index: 1000;
				}
				
				.next {
					width: 34px;
					height: 34px;
					margin: 0 auto;
					margin-top: 20px;
				}
			}
			
			.videos {
				padding-top: 100rem;
				width: 100%;
				height: 100%;
				background-image: url(../assets/img/video_bg.png);
				video {
					padding: 5%;
					width: 100%;
					height: 100%;
				}
			}

			.boardWrod {
				width: 96%;
				margin: 20px auto;
				font-size: 22rem;
				color: #484f5a;
				line-height: 36rem;

				span {
					color: #0079ff;
					;
				}
			}

			.bg {
				width: 100%;
				height: 830rem;
				background: url(../assets/img/pcbg.png) no-repeat;
				background-size: 100% 100%;

				.none {
					height: 60rem;
				}

				.sign {
					width: 100%;
					height: 38rem;
					margin: 0 auto;
					background: url(../assets/img/web/title2.png) no-repeat;
					background-position: center;
					padding: 20rem 0;
					margin-bottom: 40px;
				}
				

				.form {
					width: 800rem;
					height: 620rem;
					margin: 0 auto;
					background: url(../assets/img/h5kuang.png) no-repeat;
					background-size: 100% 100%;
					padding: 0 80rem;
					margin-top: 35rem;

					p:nth-child(1) {
						padding: 115rem 0 20px 0;
					}

					p {
						display: flex;
						align-items: center;
						height: 50rem;
						width: 96%;
						margin: 16px 0;
						font-size: 18rem;

						b {
							font-weight: normal;
							flex-shrink: 0;
							width: 200rem;
						}

						span {
							flex: 1;
							height: 50rem;
							background-color: #f5f5f5;
							display: flex;
							align-items: center;
							margin-left: 24rem;
							border-radius: 35rem;

							img {
								margin: 0 10px 0 26rem;
							}

							.passInp {
								width: 80%;
							}

							.passIcon {
								width: 30rem;
								height: 28rem;
							}

							.userImg {
								width: 23rem;
								height: 23rem;
							}

							.telImg {
								width: 12rem;
								height: 27rem;
							}

							.codeImg {
								width: 21rem;
								height: 25rem;
							}

							.passImg {
								width: 20rem;
								height: 22rem;
							}

							.emailImg {
								width: 21rem;
								height: 16rem;
							}

							input {
								width: 80%;
								border: none;
								outline: none;
								background-color: #f5f5f5;
							}
						}

						.tel {
							width: 77%;

							img {
								flex-shrink: 0;
							}

							input {
								width: 122px;
							}
						}

						.code {
							width: 50%;

							input {
								width: 80%;
							}
						}

						.codeBtn {
							width: 100px;
							height: 50rem;
							background: url(../assets/img/h5copy_tutton.png) no-repeat;
							background-size: 100% 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 16rem;
							color: #fff;
						}
					}

					.signBtn {
						width: 320rem;
						height: 66rem;
						background: url('../assets/img/h5submit_button.png') no-repeat;
						background-size: 100% 100%;
						line-height: 66rem;
						text-align: center;
						margin: 0 auto;
						color: #fff;
						font-size: 20rem;
						margin-top: 66rem;
					}
				}
			}

			.dov {
				width: 100%;
				height: 650rem;

				.sign {
					width: 100%;
					height: 36rem;
					margin-top: 50rem;
					margin-bottom: 80rem;
					background: url(../assets/img/web/title3.png) no-repeat;
					background-position: center;
				}
				
				.sign2 {
					width: 100%;
					height: 36rem;
					margin-top: -50rem;
					margin-bottom: 80rem;
					background: url(../assets/img/web/title4.png) no-repeat;
					background-position: center;
				}

				.dovForm {
					width: 61%;
					margin: 1rem auto;
					font-size: 24rem;
					text-align: center;

					p {
						height: 70rem;
						display: flex;
						align-items: center;
						margin: 20rem 0;

						input {
							display: inline-block;
							flex: 1;
							height: 70rem;
							margin-left: 10rem;
							background-color: #f5f5f5;
							border-radius: 35rem;
							display: flex;
							align-items: center;
							padding-left: 40rem;
							font-size: 18rem;
						}
					}

					.num {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;

						p {
							height: 70rem;
							display: flex;
							align-items: center;
							margin: 20rem 0;
							flex: 1;

							input {
								display: inline-block;
								width: 470rem;
								height: 70rem;
								margin-left: 10rem;
								background-color: #f5f5f5;
								border-radius: 35rem;
								flex: 1;
								display: flex;
								align-items: center;
								padding-left: 40rem;
							}
						}

						b {
							margin-left: 20rem;
							font-weight: normal;
							color: #81888e;
							font-size: 16rem;

						}
					}

					.btn {
						width: 300rem;
						height: 80rem;
						margin: 50rem auto;
						display: flex;
						align-items: center;
						justify-content: center;
						background: url(../assets/img/h5copy_tutton.png) no-repeat;
						background-size: 100% 100%;
						font-size: 28rem;
						color: #fff;

						img {
							width: 34rem;
							height: 36rem;
							margin-right: 10rem;
						}
					}
				}
			}

		}
	}
</style>
