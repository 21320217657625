// source: exhibition.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var user_pb = require('./user_pb.js');
goog.object.extend(proto, user_pb);
var company_pb = require('./company_pb.js');
goog.object.extend(proto, company_pb);
goog.exportSymbol('proto.pbs.Exhibition', null, global);
goog.exportSymbol('proto.pbs.ExhibitionId', null, global);
goog.exportSymbol('proto.pbs.ExhibitionListReq', null, global);
goog.exportSymbol('proto.pbs.ExhibitionReq', null, global);
goog.exportSymbol('proto.pbs.Exhibitions', null, global);
goog.exportSymbol('proto.pbs.ImageInfo', null, global);
goog.exportSymbol('proto.pbs.Media', null, global);
goog.exportSymbol('proto.pbs.MediaIntro', null, global);
goog.exportSymbol('proto.pbs.ModelFile', null, global);
goog.exportSymbol('proto.pbs.ShareRegisterReq', null, global);
goog.exportSymbol('proto.pbs.UpdateAdvertiseReq', null, global);
goog.exportSymbol('proto.pbs.UpdatePointReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ExhibitionId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ExhibitionId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ExhibitionId.displayName = 'proto.pbs.ExhibitionId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UpdateAdvertiseReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.UpdateAdvertiseReq.repeatedFields_, null);
};
goog.inherits(proto.pbs.UpdateAdvertiseReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UpdateAdvertiseReq.displayName = 'proto.pbs.UpdateAdvertiseReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ShareRegisterReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ShareRegisterReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ShareRegisterReq.displayName = 'proto.pbs.ShareRegisterReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UpdatePointReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.UpdatePointReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UpdatePointReq.displayName = 'proto.pbs.UpdatePointReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ExhibitionListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ExhibitionListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ExhibitionListReq.displayName = 'proto.pbs.ExhibitionListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Exhibitions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Exhibitions.repeatedFields_, null);
};
goog.inherits(proto.pbs.Exhibitions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Exhibitions.displayName = 'proto.pbs.Exhibitions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ExhibitionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ExhibitionReq.repeatedFields_, null);
};
goog.inherits(proto.pbs.ExhibitionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ExhibitionReq.displayName = 'proto.pbs.ExhibitionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Media = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Media, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Media.displayName = 'proto.pbs.Media';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MediaIntro = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MediaIntro, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MediaIntro.displayName = 'proto.pbs.MediaIntro';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ImageInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ImageInfo.repeatedFields_, null);
};
goog.inherits(proto.pbs.ImageInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ImageInfo.displayName = 'proto.pbs.ImageInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ModelFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ModelFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ModelFile.displayName = 'proto.pbs.ModelFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Exhibition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Exhibition.repeatedFields_, null);
};
goog.inherits(proto.pbs.Exhibition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Exhibition.displayName = 'proto.pbs.Exhibition';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ExhibitionId.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ExhibitionId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ExhibitionId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExhibitionId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ExhibitionId}
 */
proto.pbs.ExhibitionId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ExhibitionId;
  return proto.pbs.ExhibitionId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ExhibitionId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ExhibitionId}
 */
proto.pbs.ExhibitionId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ExhibitionId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ExhibitionId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ExhibitionId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExhibitionId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.ExhibitionId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionId} returns this
 */
proto.pbs.ExhibitionId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.UpdateAdvertiseReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UpdateAdvertiseReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UpdateAdvertiseReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UpdateAdvertiseReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdateAdvertiseReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertiseList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UpdateAdvertiseReq}
 */
proto.pbs.UpdateAdvertiseReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UpdateAdvertiseReq;
  return proto.pbs.UpdateAdvertiseReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UpdateAdvertiseReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UpdateAdvertiseReq}
 */
proto.pbs.UpdateAdvertiseReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdvertise(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UpdateAdvertiseReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UpdateAdvertiseReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UpdateAdvertiseReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdateAdvertiseReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertiseList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.UpdateAdvertiseReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdateAdvertiseReq} returns this
 */
proto.pbs.UpdateAdvertiseReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string advertise = 2;
 * @return {!Array<string>}
 */
proto.pbs.UpdateAdvertiseReq.prototype.getAdvertiseList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.UpdateAdvertiseReq} returns this
 */
proto.pbs.UpdateAdvertiseReq.prototype.setAdvertiseList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.UpdateAdvertiseReq} returns this
 */
proto.pbs.UpdateAdvertiseReq.prototype.addAdvertise = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.UpdateAdvertiseReq} returns this
 */
proto.pbs.UpdateAdvertiseReq.prototype.clearAdvertiseList = function() {
  return this.setAdvertiseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ShareRegisterReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ShareRegisterReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ShareRegisterReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ShareRegisterReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    exhibitionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 4, ""),
    code: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ShareRegisterReq}
 */
proto.pbs.ShareRegisterReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ShareRegisterReq;
  return proto.pbs.ShareRegisterReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ShareRegisterReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ShareRegisterReq}
 */
proto.pbs.ShareRegisterReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExhibitionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ShareRegisterReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ShareRegisterReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ShareRegisterReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ShareRegisterReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExhibitionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string exhibition_id = 1;
 * @return {string}
 */
proto.pbs.ShareRegisterReq.prototype.getExhibitionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ShareRegisterReq} returns this
 */
proto.pbs.ShareRegisterReq.prototype.setExhibitionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.pbs.ShareRegisterReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ShareRegisterReq} returns this
 */
proto.pbs.ShareRegisterReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.pbs.ShareRegisterReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ShareRegisterReq} returns this
 */
proto.pbs.ShareRegisterReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mobile = 4;
 * @return {string}
 */
proto.pbs.ShareRegisterReq.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ShareRegisterReq} returns this
 */
proto.pbs.ShareRegisterReq.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string code = 5;
 * @return {string}
 */
proto.pbs.ShareRegisterReq.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ShareRegisterReq} returns this
 */
proto.pbs.ShareRegisterReq.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.pbs.ShareRegisterReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ShareRegisterReq} returns this
 */
proto.pbs.ShareRegisterReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UpdatePointReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UpdatePointReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UpdatePointReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdatePointReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    index: jspb.Message.getFieldWithDefault(msg, 2, 0),
    media: (f = msg.getMedia()) && proto.pbs.Media.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UpdatePointReq}
 */
proto.pbs.UpdatePointReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UpdatePointReq;
  return proto.pbs.UpdatePointReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UpdatePointReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UpdatePointReq}
 */
proto.pbs.UpdatePointReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 3:
      var value = new proto.pbs.Media;
      reader.readMessage(value,proto.pbs.Media.deserializeBinaryFromReader);
      msg.setMedia(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UpdatePointReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UpdatePointReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UpdatePointReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdatePointReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.Media.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.UpdatePointReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdatePointReq} returns this
 */
proto.pbs.UpdatePointReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 index = 2;
 * @return {number}
 */
proto.pbs.UpdatePointReq.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UpdatePointReq} returns this
 */
proto.pbs.UpdatePointReq.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Media media = 3;
 * @return {?proto.pbs.Media}
 */
proto.pbs.UpdatePointReq.prototype.getMedia = function() {
  return /** @type{?proto.pbs.Media} */ (
    jspb.Message.getWrapperField(this, proto.pbs.Media, 3));
};


/**
 * @param {?proto.pbs.Media|undefined} value
 * @return {!proto.pbs.UpdatePointReq} returns this
*/
proto.pbs.UpdatePointReq.prototype.setMedia = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.UpdatePointReq} returns this
 */
proto.pbs.UpdatePointReq.prototype.clearMedia = function() {
  return this.setMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.UpdatePointReq.prototype.hasMedia = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ExhibitionListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ExhibitionListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ExhibitionListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExhibitionListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ExhibitionListReq}
 */
proto.pbs.ExhibitionListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ExhibitionListReq;
  return proto.pbs.ExhibitionListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ExhibitionListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ExhibitionListReq}
 */
proto.pbs.ExhibitionListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ExhibitionListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ExhibitionListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ExhibitionListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExhibitionListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string keyword = 1;
 * @return {string}
 */
proto.pbs.ExhibitionListReq.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionListReq} returns this
 */
proto.pbs.ExhibitionListReq.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string company_id = 2;
 * @return {string}
 */
proto.pbs.ExhibitionListReq.prototype.getCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionListReq} returns this
 */
proto.pbs.ExhibitionListReq.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 page = 3;
 * @return {number}
 */
proto.pbs.ExhibitionListReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExhibitionListReq} returns this
 */
proto.pbs.ExhibitionListReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 page_size = 4;
 * @return {number}
 */
proto.pbs.ExhibitionListReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExhibitionListReq} returns this
 */
proto.pbs.ExhibitionListReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Exhibitions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Exhibitions.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Exhibitions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Exhibitions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Exhibitions.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.Exhibition.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Exhibitions}
 */
proto.pbs.Exhibitions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Exhibitions;
  return proto.pbs.Exhibitions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Exhibitions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Exhibitions}
 */
proto.pbs.Exhibitions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.Exhibition;
      reader.readMessage(value,proto.pbs.Exhibition.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Exhibitions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Exhibitions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Exhibitions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Exhibitions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.Exhibition.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Exhibition data = 1;
 * @return {!Array<!proto.pbs.Exhibition>}
 */
proto.pbs.Exhibitions.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.Exhibition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Exhibition, 1));
};


/**
 * @param {!Array<!proto.pbs.Exhibition>} value
 * @return {!proto.pbs.Exhibitions} returns this
*/
proto.pbs.Exhibitions.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Exhibition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Exhibition}
 */
proto.pbs.Exhibitions.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Exhibition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Exhibitions} returns this
 */
proto.pbs.Exhibitions.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.Exhibitions.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exhibitions} returns this
 */
proto.pbs.Exhibitions.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ExhibitionReq.repeatedFields_ = [6,7,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ExhibitionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ExhibitionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ExhibitionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExhibitionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    desc: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    clientsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    salesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    id: jspb.Message.getFieldWithDefault(msg, 8, ""),
    mediaList: jspb.Message.toObjectList(msg.getMediaList(),
    proto.pbs.Media.toObject, includeInstance),
    pointNum: jspb.Message.getFieldWithDefault(msg, 10, 0),
    publicImage: jspb.Message.getFieldWithDefault(msg, 11, ""),
    publicVideo: jspb.Message.getFieldWithDefault(msg, 12, ""),
    modelFile: (f = msg.getModelFile()) && proto.pbs.ModelFile.toObject(includeInstance, f),
    advertiseNum: jspb.Message.getFieldWithDefault(msg, 14, 0),
    netdiskType: jspb.Message.getFieldWithDefault(msg, 15, ""),
    netdiskDownloadUrl: jspb.Message.getFieldWithDefault(msg, 16, ""),
    netdiskDownloadPassword: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ExhibitionReq}
 */
proto.pbs.ExhibitionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ExhibitionReq;
  return proto.pbs.ExhibitionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ExhibitionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ExhibitionReq}
 */
proto.pbs.ExhibitionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesc(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addClients(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addSales(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 9:
      var value = new proto.pbs.Media;
      reader.readMessage(value,proto.pbs.Media.deserializeBinaryFromReader);
      msg.addMedia(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointNum(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicImage(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicVideo(value);
      break;
    case 13:
      var value = new proto.pbs.ModelFile;
      reader.readMessage(value,proto.pbs.ModelFile.deserializeBinaryFromReader);
      msg.setModelFile(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertiseNum(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetdiskType(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetdiskDownloadUrl(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetdiskDownloadPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ExhibitionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ExhibitionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ExhibitionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExhibitionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDesc();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getClientsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getSalesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMediaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.pbs.Media.serializeBinaryToWriter
    );
  }
  f = message.getPointNum();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getPublicImage();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPublicVideo();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getModelFile();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.pbs.ModelFile.serializeBinaryToWriter
    );
  }
  f = message.getAdvertiseNum();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getNetdiskType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getNetdiskDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getNetdiskDownloadPassword();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.pbs.ExhibitionReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string desc = 2;
 * @return {string}
 */
proto.pbs.ExhibitionReq.prototype.getDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string company_id = 3;
 * @return {string}
 */
proto.pbs.ExhibitionReq.prototype.getCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 start_time = 4;
 * @return {number}
 */
proto.pbs.ExhibitionReq.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 end_time = 5;
 * @return {number}
 */
proto.pbs.ExhibitionReq.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated string clients = 6;
 * @return {!Array<string>}
 */
proto.pbs.ExhibitionReq.prototype.getClientsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setClientsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.addClients = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.clearClientsList = function() {
  return this.setClientsList([]);
};


/**
 * repeated string sales = 7;
 * @return {!Array<string>}
 */
proto.pbs.ExhibitionReq.prototype.getSalesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setSalesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.addSales = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.clearSalesList = function() {
  return this.setSalesList([]);
};


/**
 * optional string id = 8;
 * @return {string}
 */
proto.pbs.ExhibitionReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated Media media = 9;
 * @return {!Array<!proto.pbs.Media>}
 */
proto.pbs.ExhibitionReq.prototype.getMediaList = function() {
  return /** @type{!Array<!proto.pbs.Media>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Media, 9));
};


/**
 * @param {!Array<!proto.pbs.Media>} value
 * @return {!proto.pbs.ExhibitionReq} returns this
*/
proto.pbs.ExhibitionReq.prototype.setMediaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.pbs.Media=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Media}
 */
proto.pbs.ExhibitionReq.prototype.addMedia = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.pbs.Media, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.clearMediaList = function() {
  return this.setMediaList([]);
};


/**
 * optional int32 point_num = 10;
 * @return {number}
 */
proto.pbs.ExhibitionReq.prototype.getPointNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setPointNum = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string public_image = 11;
 * @return {string}
 */
proto.pbs.ExhibitionReq.prototype.getPublicImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setPublicImage = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string public_video = 12;
 * @return {string}
 */
proto.pbs.ExhibitionReq.prototype.getPublicVideo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setPublicVideo = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional ModelFile model_file = 13;
 * @return {?proto.pbs.ModelFile}
 */
proto.pbs.ExhibitionReq.prototype.getModelFile = function() {
  return /** @type{?proto.pbs.ModelFile} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ModelFile, 13));
};


/**
 * @param {?proto.pbs.ModelFile|undefined} value
 * @return {!proto.pbs.ExhibitionReq} returns this
*/
proto.pbs.ExhibitionReq.prototype.setModelFile = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.clearModelFile = function() {
  return this.setModelFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExhibitionReq.prototype.hasModelFile = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int32 advertise_num = 14;
 * @return {number}
 */
proto.pbs.ExhibitionReq.prototype.getAdvertiseNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setAdvertiseNum = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string netdisk_type = 15;
 * @return {string}
 */
proto.pbs.ExhibitionReq.prototype.getNetdiskType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setNetdiskType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string netdisk_download_url = 16;
 * @return {string}
 */
proto.pbs.ExhibitionReq.prototype.getNetdiskDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setNetdiskDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string netdisk_download_password = 17;
 * @return {string}
 */
proto.pbs.ExhibitionReq.prototype.getNetdiskDownloadPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExhibitionReq} returns this
 */
proto.pbs.ExhibitionReq.prototype.setNetdiskDownloadPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Media.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Media.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Media} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Media.toObject = function(includeInstance, msg) {
  var f, obj = {
    intro: (f = msg.getIntro()) && proto.pbs.MediaIntro.toObject(includeInstance, f),
    image: (f = msg.getImage()) && proto.pbs.ImageInfo.toObject(includeInstance, f),
    video: jspb.Message.getFieldWithDefault(msg, 3, ""),
    audio: (f = msg.getAudio()) && proto.pbs.MediaIntro.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Media}
 */
proto.pbs.Media.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Media;
  return proto.pbs.Media.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Media} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Media}
 */
proto.pbs.Media.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.MediaIntro;
      reader.readMessage(value,proto.pbs.MediaIntro.deserializeBinaryFromReader);
      msg.setIntro(value);
      break;
    case 2:
      var value = new proto.pbs.ImageInfo;
      reader.readMessage(value,proto.pbs.ImageInfo.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideo(value);
      break;
    case 4:
      var value = new proto.pbs.MediaIntro;
      reader.readMessage(value,proto.pbs.MediaIntro.deserializeBinaryFromReader);
      msg.setAudio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Media.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Media.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Media} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Media.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntro();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.MediaIntro.serializeBinaryToWriter
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.ImageInfo.serializeBinaryToWriter
    );
  }
  f = message.getVideo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAudio();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.MediaIntro.serializeBinaryToWriter
    );
  }
};


/**
 * optional MediaIntro intro = 1;
 * @return {?proto.pbs.MediaIntro}
 */
proto.pbs.Media.prototype.getIntro = function() {
  return /** @type{?proto.pbs.MediaIntro} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MediaIntro, 1));
};


/**
 * @param {?proto.pbs.MediaIntro|undefined} value
 * @return {!proto.pbs.Media} returns this
*/
proto.pbs.Media.prototype.setIntro = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Media} returns this
 */
proto.pbs.Media.prototype.clearIntro = function() {
  return this.setIntro(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Media.prototype.hasIntro = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ImageInfo image = 2;
 * @return {?proto.pbs.ImageInfo}
 */
proto.pbs.Media.prototype.getImage = function() {
  return /** @type{?proto.pbs.ImageInfo} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ImageInfo, 2));
};


/**
 * @param {?proto.pbs.ImageInfo|undefined} value
 * @return {!proto.pbs.Media} returns this
*/
proto.pbs.Media.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Media} returns this
 */
proto.pbs.Media.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Media.prototype.hasImage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string video = 3;
 * @return {string}
 */
proto.pbs.Media.prototype.getVideo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Media} returns this
 */
proto.pbs.Media.prototype.setVideo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MediaIntro audio = 4;
 * @return {?proto.pbs.MediaIntro}
 */
proto.pbs.Media.prototype.getAudio = function() {
  return /** @type{?proto.pbs.MediaIntro} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MediaIntro, 4));
};


/**
 * @param {?proto.pbs.MediaIntro|undefined} value
 * @return {!proto.pbs.Media} returns this
*/
proto.pbs.Media.prototype.setAudio = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Media} returns this
 */
proto.pbs.Media.prototype.clearAudio = function() {
  return this.setAudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Media.prototype.hasAudio = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MediaIntro.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MediaIntro.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MediaIntro} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MediaIntro.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MediaIntro}
 */
proto.pbs.MediaIntro.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MediaIntro;
  return proto.pbs.MediaIntro.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MediaIntro} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MediaIntro}
 */
proto.pbs.MediaIntro.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MediaIntro.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MediaIntro.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MediaIntro} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MediaIntro.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.pbs.MediaIntro.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MediaIntro} returns this
 */
proto.pbs.MediaIntro.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.pbs.MediaIntro.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MediaIntro} returns this
 */
proto.pbs.MediaIntro.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ImageInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ImageInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ImageInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ImageInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ImageInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imagesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ImageInfo}
 */
proto.pbs.ImageInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ImageInfo;
  return proto.pbs.ImageInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ImageInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ImageInfo}
 */
proto.pbs.ImageInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ImageInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ImageInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ImageInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ImageInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.pbs.ImageInfo.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ImageInfo} returns this
 */
proto.pbs.ImageInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string images = 2;
 * @return {!Array<string>}
 */
proto.pbs.ImageInfo.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.ImageInfo} returns this
 */
proto.pbs.ImageInfo.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.ImageInfo} returns this
 */
proto.pbs.ImageInfo.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ImageInfo} returns this
 */
proto.pbs.ImageInfo.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ModelFile.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ModelFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ModelFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ModelFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    zipPath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelPath: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ModelFile}
 */
proto.pbs.ModelFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ModelFile;
  return proto.pbs.ModelFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ModelFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ModelFile}
 */
proto.pbs.ModelFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipPath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ModelFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ModelFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ModelFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ModelFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZipPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string zip_path = 1;
 * @return {string}
 */
proto.pbs.ModelFile.prototype.getZipPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ModelFile} returns this
 */
proto.pbs.ModelFile.prototype.setZipPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_path = 2;
 * @return {string}
 */
proto.pbs.ModelFile.prototype.getModelPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ModelFile} returns this
 */
proto.pbs.ModelFile.prototype.setModelPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Exhibition.repeatedFields_ = [6,7,12,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Exhibition.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Exhibition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Exhibition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Exhibition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    desc: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    clientsList: jspb.Message.toObjectList(msg.getClientsList(),
    user_pb.User.toObject, includeInstance),
    salesList: jspb.Message.toObjectList(msg.getSalesList(),
    user_pb.User.toObject, includeInstance),
    company: (f = msg.getCompany()) && company_pb.Company.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    mediasList: jspb.Message.toObjectList(msg.getMediasList(),
    proto.pbs.Media.toObject, includeInstance),
    pointNum: jspb.Message.getFieldWithDefault(msg, 13, 0),
    publicImage: jspb.Message.getFieldWithDefault(msg, 14, ""),
    publicVideo: jspb.Message.getFieldWithDefault(msg, 15, ""),
    advertiseList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    modelFile: (f = msg.getModelFile()) && proto.pbs.ModelFile.toObject(includeInstance, f),
    advertiseNum: jspb.Message.getFieldWithDefault(msg, 18, 0),
    netdiskType: jspb.Message.getFieldWithDefault(msg, 19, ""),
    netdiskDownloadUrl: jspb.Message.getFieldWithDefault(msg, 20, ""),
    netdiskDownloadPassword: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Exhibition}
 */
proto.pbs.Exhibition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Exhibition;
  return proto.pbs.Exhibition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Exhibition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Exhibition}
 */
proto.pbs.Exhibition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesc(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 6:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.addClients(value);
      break;
    case 7:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.addSales(value);
      break;
    case 11:
      var value = new company_pb.Company;
      reader.readMessage(value,company_pb.Company.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedAt(value);
      break;
    case 12:
      var value = new proto.pbs.Media;
      reader.readMessage(value,proto.pbs.Media.deserializeBinaryFromReader);
      msg.addMedias(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointNum(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicImage(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicVideo(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdvertise(value);
      break;
    case 17:
      var value = new proto.pbs.ModelFile;
      reader.readMessage(value,proto.pbs.ModelFile.deserializeBinaryFromReader);
      msg.setModelFile(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertiseNum(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetdiskType(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetdiskDownloadUrl(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetdiskDownloadPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Exhibition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Exhibition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Exhibition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Exhibition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDesc();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getClientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getSalesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      company_pb.Company.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDeletedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getMediasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.pbs.Media.serializeBinaryToWriter
    );
  }
  f = message.getPointNum();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getPublicImage();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPublicVideo();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAdvertiseList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getModelFile();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.pbs.ModelFile.serializeBinaryToWriter
    );
  }
  f = message.getAdvertiseNum();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getNetdiskType();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getNetdiskDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getNetdiskDownloadPassword();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.Exhibition.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.pbs.Exhibition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string desc = 3;
 * @return {string}
 */
proto.pbs.Exhibition.prototype.getDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 start_time = 4;
 * @return {number}
 */
proto.pbs.Exhibition.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 end_time = 5;
 * @return {number}
 */
proto.pbs.Exhibition.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated User clients = 6;
 * @return {!Array<!proto.pbs.User>}
 */
proto.pbs.Exhibition.prototype.getClientsList = function() {
  return /** @type{!Array<!proto.pbs.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, user_pb.User, 6));
};


/**
 * @param {!Array<!proto.pbs.User>} value
 * @return {!proto.pbs.Exhibition} returns this
*/
proto.pbs.Exhibition.prototype.setClientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.User}
 */
proto.pbs.Exhibition.prototype.addClients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.clearClientsList = function() {
  return this.setClientsList([]);
};


/**
 * repeated User sales = 7;
 * @return {!Array<!proto.pbs.User>}
 */
proto.pbs.Exhibition.prototype.getSalesList = function() {
  return /** @type{!Array<!proto.pbs.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, user_pb.User, 7));
};


/**
 * @param {!Array<!proto.pbs.User>} value
 * @return {!proto.pbs.Exhibition} returns this
*/
proto.pbs.Exhibition.prototype.setSalesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.pbs.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.User}
 */
proto.pbs.Exhibition.prototype.addSales = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pbs.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.clearSalesList = function() {
  return this.setSalesList([]);
};


/**
 * optional Company company = 11;
 * @return {?proto.pbs.Company}
 */
proto.pbs.Exhibition.prototype.getCompany = function() {
  return /** @type{?proto.pbs.Company} */ (
    jspb.Message.getWrapperField(this, company_pb.Company, 11));
};


/**
 * @param {?proto.pbs.Company|undefined} value
 * @return {!proto.pbs.Exhibition} returns this
*/
proto.pbs.Exhibition.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Exhibition.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.pbs.Exhibition.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.pbs.Exhibition.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 deleted_at = 10;
 * @return {number}
 */
proto.pbs.Exhibition.prototype.getDeletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated Media medias = 12;
 * @return {!Array<!proto.pbs.Media>}
 */
proto.pbs.Exhibition.prototype.getMediasList = function() {
  return /** @type{!Array<!proto.pbs.Media>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Media, 12));
};


/**
 * @param {!Array<!proto.pbs.Media>} value
 * @return {!proto.pbs.Exhibition} returns this
*/
proto.pbs.Exhibition.prototype.setMediasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.pbs.Media=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Media}
 */
proto.pbs.Exhibition.prototype.addMedias = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.pbs.Media, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.clearMediasList = function() {
  return this.setMediasList([]);
};


/**
 * optional int32 point_num = 13;
 * @return {number}
 */
proto.pbs.Exhibition.prototype.getPointNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setPointNum = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string public_image = 14;
 * @return {string}
 */
proto.pbs.Exhibition.prototype.getPublicImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setPublicImage = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string public_video = 15;
 * @return {string}
 */
proto.pbs.Exhibition.prototype.getPublicVideo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setPublicVideo = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated string advertise = 16;
 * @return {!Array<string>}
 */
proto.pbs.Exhibition.prototype.getAdvertiseList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setAdvertiseList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.addAdvertise = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.clearAdvertiseList = function() {
  return this.setAdvertiseList([]);
};


/**
 * optional ModelFile model_file = 17;
 * @return {?proto.pbs.ModelFile}
 */
proto.pbs.Exhibition.prototype.getModelFile = function() {
  return /** @type{?proto.pbs.ModelFile} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ModelFile, 17));
};


/**
 * @param {?proto.pbs.ModelFile|undefined} value
 * @return {!proto.pbs.Exhibition} returns this
*/
proto.pbs.Exhibition.prototype.setModelFile = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.clearModelFile = function() {
  return this.setModelFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Exhibition.prototype.hasModelFile = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional int32 advertise_num = 18;
 * @return {number}
 */
proto.pbs.Exhibition.prototype.getAdvertiseNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setAdvertiseNum = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string netdisk_type = 19;
 * @return {string}
 */
proto.pbs.Exhibition.prototype.getNetdiskType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setNetdiskType = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string netdisk_download_url = 20;
 * @return {string}
 */
proto.pbs.Exhibition.prototype.getNetdiskDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setNetdiskDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string netdisk_download_password = 21;
 * @return {string}
 */
proto.pbs.Exhibition.prototype.getNetdiskDownloadPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Exhibition} returns this
 */
proto.pbs.Exhibition.prototype.setNetdiskDownloadPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


goog.object.extend(exports, proto.pbs);
