import Grpc from '@/utils/http/request';

import {
	ExhibitionId,
	ShareRegisterReq
} from "@/pbs/exhibition_pb";
import {
	ExhibitionServiceClient
} from "@/pbs/exhibition_grpc_web_pb";

class Expo extends Grpc {
	constructor() {
		super(ExhibitionServiceClient);
	}
	
	/**
	 * 展会详情
	 * @param {string} id
	 */
	Detail(id) {
		return new Promise((resolve, reject) => {
			var request = new ExhibitionId();
			request.setId(id);
			this.service.detail(request, this.metadata, (err, response) => {
				if (err) {
					reject(err.message);
				} else {
					resolve(response);
				}
			});
		});
	}
	
	/**
	 * 注册用户到企业
	 * @param {string} id
	 * @param {string} name
	 * @param {string} passwd
	 * @param {string} mobile
	 * @param {string} code
	 * @param {string} email
	 */
	Register(data) {
		return new Promise((resolve, reject) => {
			var request = new ShareRegisterReq();
			request.setExhibitionId(data.exhibition_id);
			request.setName(data.name);
			request.setPassword(data.password);
			request.setMobile(data.mobile);
			request.setCode(data.code);
			request.setEmail(data.email);
			this.service.shareRegister(request, this.metadata, (err, response) => {
				if (err) {
					reject(err.message);
				} else {
					resolve(response);
				}
			});
		});
	}
}
// 获取验证码
export default new Expo();
