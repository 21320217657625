/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.11.4
// source: exhibition.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var user_pb = require('./user_pb.js')

var company_pb = require('./company_pb.js')
const proto = {};
proto.pbs = require('./exhibition_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.ExhibitionServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.ExhibitionServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExhibitionReq,
 *   !proto.pbs.ExhibitionId>}
 */
const methodDescriptor_ExhibitionService_Create = new grpc.web.MethodDescriptor(
  '/pbs.ExhibitionService/Create',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExhibitionReq,
  proto.pbs.ExhibitionId,
  /**
   * @param {!proto.pbs.ExhibitionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.ExhibitionId.deserializeBinary
);


/**
 * @param {!proto.pbs.ExhibitionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.ExhibitionId)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.ExhibitionId>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExhibitionServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExhibitionService/Create',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_Create,
      callback);
};


/**
 * @param {!proto.pbs.ExhibitionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.ExhibitionId>}
 *     Promise that resolves to the response
 */
proto.pbs.ExhibitionServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExhibitionService/Create',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExhibitionListReq,
 *   !proto.pbs.Exhibitions>}
 */
const methodDescriptor_ExhibitionService_List = new grpc.web.MethodDescriptor(
  '/pbs.ExhibitionService/List',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExhibitionListReq,
  proto.pbs.Exhibitions,
  /**
   * @param {!proto.pbs.ExhibitionListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exhibitions.deserializeBinary
);


/**
 * @param {!proto.pbs.ExhibitionListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Exhibitions)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Exhibitions>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExhibitionServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExhibitionService/List',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_List,
      callback);
};


/**
 * @param {!proto.pbs.ExhibitionListReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Exhibitions>}
 *     Promise that resolves to the response
 */
proto.pbs.ExhibitionServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExhibitionService/List',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExhibitionReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_ExhibitionService_Update = new grpc.web.MethodDescriptor(
  '/pbs.ExhibitionService/Update',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExhibitionReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.ExhibitionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.ExhibitionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExhibitionServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExhibitionService/Update',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_Update,
      callback);
};


/**
 * @param {!proto.pbs.ExhibitionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.ExhibitionServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExhibitionService/Update',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExhibitionId,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_ExhibitionService_Delete = new grpc.web.MethodDescriptor(
  '/pbs.ExhibitionService/Delete',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExhibitionId,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.ExhibitionId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.ExhibitionId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExhibitionServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExhibitionService/Delete',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_Delete,
      callback);
};


/**
 * @param {!proto.pbs.ExhibitionId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.ExhibitionServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExhibitionService/Delete',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExhibitionId,
 *   !proto.pbs.Exhibition>}
 */
const methodDescriptor_ExhibitionService_Detail = new grpc.web.MethodDescriptor(
  '/pbs.ExhibitionService/Detail',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExhibitionId,
  proto.pbs.Exhibition,
  /**
   * @param {!proto.pbs.ExhibitionId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exhibition.deserializeBinary
);


/**
 * @param {!proto.pbs.ExhibitionId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Exhibition)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Exhibition>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExhibitionServiceClient.prototype.detail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExhibitionService/Detail',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_Detail,
      callback);
};


/**
 * @param {!proto.pbs.ExhibitionId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Exhibition>}
 *     Promise that resolves to the response
 */
proto.pbs.ExhibitionServicePromiseClient.prototype.detail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExhibitionService/Detail',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_Detail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.UpdatePointReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_ExhibitionService_UpdatePoint = new grpc.web.MethodDescriptor(
  '/pbs.ExhibitionService/UpdatePoint',
  grpc.web.MethodType.UNARY,
  proto.pbs.UpdatePointReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.UpdatePointReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.UpdatePointReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExhibitionServiceClient.prototype.updatePoint =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExhibitionService/UpdatePoint',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_UpdatePoint,
      callback);
};


/**
 * @param {!proto.pbs.UpdatePointReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.ExhibitionServicePromiseClient.prototype.updatePoint =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExhibitionService/UpdatePoint',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_UpdatePoint);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ShareRegisterReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_ExhibitionService_ShareRegister = new grpc.web.MethodDescriptor(
  '/pbs.ExhibitionService/ShareRegister',
  grpc.web.MethodType.UNARY,
  proto.pbs.ShareRegisterReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.ShareRegisterReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.ShareRegisterReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExhibitionServiceClient.prototype.shareRegister =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExhibitionService/ShareRegister',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_ShareRegister,
      callback);
};


/**
 * @param {!proto.pbs.ShareRegisterReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.ExhibitionServicePromiseClient.prototype.shareRegister =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExhibitionService/ShareRegister',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_ShareRegister);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.UpdateAdvertiseReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_ExhibitionService_UpdateAdvertise = new grpc.web.MethodDescriptor(
  '/pbs.ExhibitionService/UpdateAdvertise',
  grpc.web.MethodType.UNARY,
  proto.pbs.UpdateAdvertiseReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.UpdateAdvertiseReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.UpdateAdvertiseReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExhibitionServiceClient.prototype.updateAdvertise =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExhibitionService/UpdateAdvertise',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_UpdateAdvertise,
      callback);
};


/**
 * @param {!proto.pbs.UpdateAdvertiseReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.ExhibitionServicePromiseClient.prototype.updateAdvertise =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExhibitionService/UpdateAdvertise',
      request,
      metadata || {},
      methodDescriptor_ExhibitionService_UpdateAdvertise);
};


module.exports = proto.pbs;

